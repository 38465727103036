import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Logo from "./logo";
import Grid from "@material-ui/core/Grid";

const styles = {
  root: {
    flexGrow: 1,
    fontFamily: "SouvenirITCbyBT"
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
};

const MenuAppBar = ({ classes }) => (
  <div className={classes.root}>
    <AppBar style={{ backgroundColor: "green" }} position="static">
      <Toolbar>
        <Typography variant="h6" color="inherit" className={classes.grow}>
          Southwest Rebar
        </Typography>
        <Typography variant="h6" color="inherit">
          (909) 947-5669
        </Typography>
      </Toolbar>
    </AppBar>
    <Grid container>
      <Grid item sm={4} xs={2} />
      <Grid item sm={4} xs={8}>
        <Logo />
      </Grid>
      <Grid item sm={4} xs={2} />
      <Grid item xs={2} />
      <Grid item xs={8}>
        <p>
          Southwest Rebar is a reinforcing steel fabricator located in
          Ontario,California. We have been in business for 35 years, reinforcing
          our sales with service.
        </p>
        <p>
          We sell stock lengths as well as cut to order steel for FOB or
          shipping. We use state of the art MEP machines for bending and cutting
          steel along with up to date computer programming for tagging orders.
          We also sell tie wire, concrete block and mesh steel.
        </p>
      </Grid>
      <Grid item xs={2} />
    </Grid>
  </div>
);

MenuAppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MenuAppBar);
